import { parentsTitles } from "components/EducationLoan/constants";

export const motherTitleOptions = [
  { label: "Mrs", value: "MRS" },
  { label: "Ms", value: "DIVORCED" },
  { label: "Late", value: "LATE" },
];

export const fatherTitleOptions = [
  { label: "Mr", value: "MR" },
  { label: "Late", value: "LATE" },
  { label: "Divorced", value: "DIVORCED" },
];

export const aptitudeTestOptions = [
  { label: "GRE", value: "GRE" },
  { label: "GMAT", value: "GMAT" },
  { label: "Tests waived off", value: "WAIVED_OFF" },
];

export const englishTestOptions = [
  { label: "TOEFL", value: "TOEFL" },
  { label: "IELTS", value: "IELTS" },
  { label: "PTE", value: "PTE" },
];

export const highestQualificationOptions = [
  {
    label: "Undergraduate (Bachelors) degree",
    value: "UG",
  },
  {
    label: "Postgraduate (Masters) degree",
    value: "PG",
  },
];

export const currentSavingsOptions = [
  { label: "0 - 1 Lakh", value: 100000 },
  { label: "1 - 5 Lakh", value: 500000 },
  { label: "5 - 10 Lakh", value: 1000000 },
  {
    label: "More than 10 Lakh",
    value: 1099997,
  },
];

export const gpaBaseOptions = [
  { label: "4", value: 4 },
  { label: "10", value: 10 },
  { label: "100", value: 100 },
];

export const yesOrNoOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const genderOptions = [
  { label: "Male", value: "M" },
  { label: "Female", value: "F" },
];

export const getCosignerRelationshipOptions = (fathersTitle, mothersTitle) =>
  [
    fathersTitle === parentsTitles.MR && {
      label: "Father",
      value: "FATHER",
    },
    mothersTitle !== parentsTitles.LATE && {
      label: "Mother",
      value: "MOTHER",
    },
    {
      label: "Grandfather",
      value: "GRANDFATHER",
    },
    {
      label: "Grandmother",
      value: "GRANDMOTHER",
    },
    { label: "Brother", value: "BROTHER" },
    { label: "Sister", value: "SISTER" },
    { label: "Uncle", value: "UNCLE" },
    { label: "Aunt", value: "AUNT" },
    { label: "Cousin", value: "COUSIN" },
    { label: "Other", value: "OTHER" },
  ].filter((relation) => !!relation);

export const dependentOptions = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10", value: 10 },
  { label: "More than 10", value: 11 },
];

export const residenceTypeOptions = [
  { label: "Rented", value: "RENTED" },
  { label: "Owned", value: "OWNED" },
];

export const cosignerEmploymentStatusOptions = [
  { label: "Salaried", value: "SALARIED" },
  {
    label: "Self-Employed",
    value: "SELFEMPLOYED",
  },
  { label: "Pensioner", value: "PENSIONER" },
];

export const totalExperienceOptions = [
  {
    label: "Less than 5 years",
    value: 30,
  },
  { label: "5 - 10 years", value: 90 },
  {
    label: "10 - 20 years",
    value: 180,
  },
  {
    label: "More than 20 years",
    value: 246,
  },
];

export const patOptions = [
  {
    label: "1 - 10 lakhs",
    value: "1 - 10,00000",
  },
  {
    label: "10 - 25 lakhs",
    value: "10,00000 - 25,00000",
  },
  {
    label: "25 - 50 lakhs",
    value: "25,00000 - 50,00000",
  },
  {
    label: "More than 50 lakhs",
    value: "more than 50,00000",
  },
];

export const referenceRelationshipOptions = [
  { label: "Father", value: "FATHER" },
  { label: "Mother", value: "MOTHER" },
  {
    label: "Grandfather",
    value: "GRANDFATHER",
  },
  {
    label: "Grandmother",
    value: "GRANDMOTHER",
  },
  {
    label: "Brother",
    value: "BROTHER",
  },
  { label: "Sister", value: "SISTER" },
  { label: "Uncle", value: "UNCLE" },
  { label: "Aunt", value: "AUNT" },
  { label: "Cousin", value: "COUSIN" },
  { label: "Other", value: "OTHER" },
];

export const applicationStatusOptions = [
  {
    label: "Admitted",
    value: "ADMITTED",
  },
  {
    label: "Applying",
    value: "APPLYING",
  },
  { label: "Others", value: "OTHER" },
];

export const residentialProofOptions = [
  {
    label: "Ration Card",
    value: "RATION_CARD",
  },
  {
    label: "Post-paid telephone bill",
    value: "TELEPHONE_BILL",
  },
  {
    label: "Gas Bill / Receipt",
    value: "GAS_BILL",
  },
  {
    label: "Electricity Bill",
    value: "ELECTRICITY_BILL",
  },
  {
    label: "Water Bill",
    value: "WATER_BILL",
  },
];

export const courseTenures = () => {
  const array = [];
  for (let i = 6; i <= 30; i++) {
    const obj = {};
    obj["label"] = `${i} months`;
    obj["value"] = i;
    array.push(obj);
  }
  return array;
};

export const tenureOptions = [
  { label: "7y", value: 84 },
  { label: "8y", value: 96 },
  { label: "9y", value: 108 },
  { label: "10y", value: 120 },
  { label: "15y", value: 180 },
];
