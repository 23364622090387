export const Pat = {
  A: "1 - 10,00000",
  B: "10,00000 - 25,00000",
  C: "25,00000 - 50,00000",
  D: "more than 50,00000",
};

export const fileTypeAndSignatureMapper = {
  jpg: "FFD8FF",
  jpeg: "FFD8FF",
  png: "89504E47",
  pdf: "25504446",
};

export const Tenure = {
  "7Y": {
    label: "7 Years",
    value: 84,
  },
  "8Y": {
    label: "8 Years",
    value: 96,
  },
  "9Y": {
    label: "9 Years",
    value: 108,
  },
  "10Y": {
    label: "10 Years",
    value: 120,
  },
  "15Y": {
    label: "15 Years",
    value: 180,
  },
};
