import axios from "axios";
import { gql } from "@apollo/client";
import apolloClient from "apolloClient";

export const requestFeeElements = (slug) =>
  apolloClient.query({
    query: gql`
      query($slug: String) {
        loanAmountChangeLog(slug: $slug) {
          currentLoanAmount
          previousLoanAmount
        }
        application(slug: $slug) {
          id
          slug
          overallProcessingFeePercentage
          upfrontProcessingFeePercentage
          sanctionFeePercentage
          loanProcessingFeePercentage
          sanctionFeeFlatCharge
          sanctionFeeCollectionStrategy
          loanFeesData {
            id
            sanctionFees
            upfrontProcessingFees
            loanFees
            sanctionFeesPostDiscount
            upfrontProcessingFeesPostDiscount
            loanFeesPostDiscount
          }
          course {
            name
            campus {
              college {
                name
              }
            }
          }
          offer {
            loanInterest
            loanAmount
            loanAmountInUSD
            loanTenure
            repaymentType
          }
          applicationState {
            screen
            status
            underwriting
            subStage
          }
        }
      }
    `,
    variables: { slug },
    fetchPolicy: "network-only",
  });

export const requestStageChange = (slug) =>
  axios.request({
    method: "get",
    url: `/api/route?path=fees-outstanding/${slug}/move-stage`,
  });

export const requestStageChangeToPFPending = requestStageChange;

export const requestOutstandingFees = (slug) =>
  axios.request({
    method: "get",
    url: `/api/route?path=fees-outstanding/${slug}/sanction-generated`,
  });

export const requestCreateOrder = (slug) =>
  axios.request({
    method: "get",
    url: `/api/route?path=fees-outstanding/${slug}/sanction-generated/create-order`,
  });

export const requestDownloadSanctionLetter = (slug) =>
  axios.request({
    method: "get",
    url: `/api/downloadFile?path=sanction/${slug}/download`,
    responseType: "blob",
  });
