var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"3ec091e764e297a18c33f92da99173877ca85e19"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/advance-website";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { CaptureConsole } from "@sentry/integrations";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new CaptureConsole({
        levels: ["error"],
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 0.25,
    ignoreErrors: [
      // Specify the error patterns or types to ignore
      'analytics.js',
      /(analytics\.js|Analytics\.js)/,
      'segment',
      /segment|Segment/
    ],
  });
}
