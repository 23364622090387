import { combineReducers } from "@reduxjs/toolkit";
import BankAccountReducer from "./bankAccountReducer";
import ApplicationListReducer from "./applicationListReducer";
import USApplicationReducer from "./usApplicationReducer";
import SanctionGeneratedDetailsReducer from "components/EducationLoan/SanctionGenerated/redux/SanctionGeneratedSlice";
import PFPendingDetailsReducer from "components/EducationLoan/PFPending/redux/PFPendingSlice";
import PFPaidDetailsReducer from "components/EducationLoan/PFPaid/redux/PFPaidSlice";
import DisbursementsReducer from "components/EducationLoan/Disbursements/redux/DisbursementsSlice";
import PersonalDetailsSlice from "components/Form/PersonalDetailsForm/redux/PersonalDetailsSlice";
import ProfessionalDetailsSlice from "components/Form/ProfessionalDetailsForm/redux/ProfessionalDetailsSlice";
import OfferDetailsSlice from "components/Form/LoanOfferScreen/redux/OfferDetailsSlice";
import CosignerDetailsSlice from "components/Form/CosignerDetailsForm/redux/CosignerDetailsSlice";
import MonthlyPaymentSlice from "components/Form/MonthlyPaymentForm/redux/MonthlyPaymentSlice";
import AddressDetailsSlice from "components/Form/AddressDetails/redux/AddressDetailsSlice";
import DocumentsUploadSlice from "components/Form/DocumentsUpload/redux/DocumentsUploadSlice";
import CosignerDocumentsUploadSlice from "components/Form/CosignerDocumentsUpload/redux/CosignerDocumentsUploadSlice";
import BureauDetailsSlice from "components/Form/Mismatch/redux/BureauDetailsSlice";
import BankDetailsUpdateSlice from "components/UpdateBankDetails/redux/BankDetailsUpdateSlice";
import ApplicationSlice from "components/Application/redux/ApplicationSlice";
import ExtraCosignerDetailsSlice from "components/Form/ExtraCosignerForm/redux/ExtraCosignerDetailsSlice";
import ExtraChargeCollectionSlice from "components/EducationLoan/ExtraChargeCollection/redux/ExtraChargeCollectionSlice";
import SSNDetailsUpdateSlice from "components/UpdateSSNDetails/redux/SSNDetailsUpdateSlice";
import ReferralSlice from "components/Referral/redux/ReferralSlice";

const rootReducer = combineReducers({
  bankAccount: BankAccountReducer,
  disbursements: DisbursementsReducer,
  applicationList: ApplicationListReducer,
  usApplication: USApplicationReducer,
  sanctionGeneratedDetails: SanctionGeneratedDetailsReducer,
  extraChargeCollectionDetails: ExtraChargeCollectionSlice,
  pfPendingDetails: PFPendingDetailsReducer,
  pfPaidDetails: PFPaidDetailsReducer,
  personalDetails: PersonalDetailsSlice,
  professionalDetails: ProfessionalDetailsSlice,
  cosignerDetails: CosignerDetailsSlice,
  offerDetails: OfferDetailsSlice,
  monthlyPaymentDetails: MonthlyPaymentSlice,
  addressDetails: AddressDetailsSlice,
  documentsUpload: DocumentsUploadSlice,
  cosignerDocumentsUpload: CosignerDocumentsUploadSlice,
  bureauDetails: BureauDetailsSlice,
  bankDetailsUpdate: BankDetailsUpdateSlice,
  application: ApplicationSlice,
  extraCosignerDetails: ExtraCosignerDetailsSlice,
  ssnDetailsUpdate: SSNDetailsUpdateSlice,
  referralDetails: ReferralSlice,
});

export default rootReducer;
